import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, CardContent, Grid, Button, TextField, Typography } from "@mui/material";
import { useAddBankInfoMutation, usePutupdateBankMutation } from "../../services/bank";
import { useLazyGetBankDetailsbYIdQuery } from "../../services/users";
import { getFromStorage, setToStorage, STORAGE_KEYS, errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { isNumber, isString } from "../../utils/validation";
import Loader from "../../helpers/Loader";

interface BankDetails {
  accountNumber: string;
  accountHolderName: string;
  swiftCode: string;
  bankName: string;
  _id?: string;
}

const BankAccount = () => {
  const location = useLocation();
  const { _id } = useParams();
  const tId = getFromStorage(STORAGE_KEYS.tutorId)
  const navigate = useNavigate();
  const [addBankApi] = useAddBankInfoMutation();
  const [updateBank] = usePutupdateBankMutation();
  const [BankDetailsById, { isLoading }] = useLazyGetBankDetailsbYIdQuery();
  const [bankDetails, setBankDetails] = useState<BankDetails>({ accountNumber: "", accountHolderName: "", swiftCode: "", bankName: "" });
  const [error, setError] = useState(false);
  const [tutorId, setTutorId] = useState<string | null>(null);

  const fetchDataById = async (tutorId: any) => {
    try {
      const response = await BankDetailsById({ userId: tutorId }).unwrap();
      if (response.statusCode === 200) {
        setBankDetails(response.data);
        console.log(bankDetails, "1111");

        formik.setValues({
          accountNumber: response.data.accountNumber,
          accountHolderName: response.data.accountHolderName,
          swiftCode: response.data.swiftCode,
          bankName: response.data.bankName
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (_id || tId) {
      
      fetchDataById(_id ? (_id!=="undefined"?_id:tId) : tId);
    }
  }, [_id, tId]);

  const formik = useFormik({
    initialValues: {
      accountNumber: "",
      accountHolderName: "",
      swiftCode: "",
      bankName: ""
    },
    validationSchema: Yup.object().shape({
      accountNumber: Yup.string()
        .required("Account number is required")
        .matches(/^\d{1,30}$/, "Account number must be less than 30 digits"),
      accountHolderName: Yup.string()
        .required("Account holder name is required")
        .max(50, "Account holder name must be less than 50 characters"),
      swiftCode: Yup.string()
        .required("Swift code is required")
        .max(30, "Swift code must be less than 30 characters"),
      bankName: Yup.string()
        .required("Bank name is required")
        .max(70, "Bank name must be less than 70 characters"),

    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const body1 = {
          accountNumber: values.accountNumber,
          accountHolderName: values.accountHolderName,
          swiftCode: values.swiftCode,
          bankName: values.bankName,
          tutorId: _id ? _id : tutorId
        };
        const body2 = {
          accountNumber: values.accountNumber,
          accountHolderName: values.accountHolderName,
          swiftCode: values.swiftCode,
          bankName: values.bankName,
          tutorId: _id ? _id : tutorId
        };
        console.log(body2, "body");

        let encryptedBody1 = generateEncryptedKeyBody(body1) as CommonBody;
        let encryptedBody2 = generateEncryptedKeyBody(body2) as CommonBody;

        if (_id) {
          const res = await updateBank({ body: encryptedBody2}).unwrap();
          if (res?.statusCode === 200) {
            successToast("Bank Details Updated Successfully");
            navigate(`/manage-tutors/add`, { state: { tab: 2 } })

          }
        } else {
          const response = await addBankApi(encryptedBody1).unwrap();
          if (response?.statusCode === 200)
            successToast("Bank Details Added Successfully");
        }
      }
      catch (error: any) {
        errorToast(error?.data?.message);
        console.error("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }

  });
  useEffect(() => {
    const storedTutorId = localStorage.getItem("tutorId");
    if (storedTutorId) {
      setTutorId(storedTutorId);
    }
  }, []);
  return (
    <div className="main_layout">
      <div className="dashboard">
        <h1 className="mn_hdng">{location.state?.type === "edit" ? "Edit Tutor" : "Account Details"}</h1>
        <Button className="btn btn_primary" onClick={() => navigate("/manage-tutors")}>
          Back
        </Button>
      </div>
      <Loader isLoad={isLoading} />
      <Card className="cards">
        <form onSubmit={formik.handleSubmit}>
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} className="heading">
                <Typography className="custom_label" variant="h5">
                  A/C Details
                </Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">A/C Number</Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type="text"
                  name="accountNumber"
                  variant="outlined"
                  fullWidth
                  placeholder="A/C Number"
                  value={formik.values.accountNumber}
                  onChange={(val) => {
                    if (
                      val.target.value === " " ||
                      val.target.value === "."
                    ) {
                    } else if (isNumber(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
                  helperText={formik.touched.accountNumber && formik.errors.accountNumber}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">A/C Holder Name</Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type="text"
                  name="accountHolderName"
                  variant="outlined"
                  fullWidth
                  placeholder="A/C Holder Name"
                  value={formik.values.accountHolderName}
                  onChange={(val) => {
                    if (
                      val.target.value === " " ||
                      val.target.value === "."
                    ) {
                    } else if (isString(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.accountHolderName && Boolean(formik.errors.accountHolderName)}
                  helperText={formik.touched.accountHolderName && formik.errors.accountHolderName}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">SWIFT code</Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type="text"
                  name="swiftCode"
                  variant="outlined"
                  fullWidth
                  placeholder="SWIFT code"
                  value={formik.values.swiftCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.swiftCode && Boolean(formik.errors.swiftCode)}
                  helperText={formik.touched.swiftCode && formik.errors.swiftCode}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography className="custom_label">Bank name</Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type="text"
                  name="bankName"
                  variant="outlined"
                  fullWidth
                  placeholder="Bank name"
                  value={formik.values.bankName}
                  onChange={(val) => {
                    if (
                      val.target.value === " " ||
                      val.target.value === "."
                    ) {
                    } else if (isString(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.bankName && Boolean(formik.errors.bankName)}
                  helperText={formik.touched.bankName && formik.errors.bankName}
                />

              </Grid>
            </Grid>
            <div className="form_btn">
              <Button size="large" type="submit" className="btn btn_primary" onClick={() => setError(true)}>
                Submit
              </Button>
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
};

export default BankAccount;
